.container-footer {
    height: 466px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 5;
    background-image: var(--bgi-footer-wave);
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (max-width: 1200px) {
        width: 100%;
        height: 650px;
        background-image: var(--bgi-footer-wave-mobile);
    }

    @media screen and (max-width: 600px) {
        height: 700px;
    }
}

.footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-top: none;
    color: var(--b-color-text-primary-2);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: var(--b-font-family-primary-1);
    font-size: 18px;
    font-style: normal;
    font-weight: 420;
    line-height: normal;
    z-index: 5;

    @media screen and (max-width: 600px) {
        font-size: 14px;
    }

    &__logo {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__networks-logo {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
        margin-top: 20px;

        @media screen and (max-width: 600px) {
            // width: 60%;

            & svg {
                height: 25px;
            }
        }
    }

    &__about {
        display: flex;
        align-items: flex-start;
        gap: 25px;

        @media (max-width: 1199px) {
            // display: none;
        }

        @media (max-width: 600px) {
            flex-direction: column;
            align-items: center;
        }
    }

    &__content {
        justify-content: space-between;
        display: flex;
        width: 1100px;
        align-items: flex-start;
        gap: 80px;

        @media screen and (max-width: 1200px) {
            width: 100%;
            flex-direction: column;
            align-items: center;
            padding: 0 40px;
            gap: 25px;
        }
    }

    &__logo-icons {
        display: flex;
        flex-direction: row;
        column-gap: 15px;

        &>img {
            @media screen and (max-width: 600px) {
                height: 70px;
            }
        }
    }

    &__links {
        margin-top: 25px;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
    }

    &__row {
        &__item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            max-width: 364px;
            min-width: 200px;

            @media screen and (max-width: 900px) {
                min-width: unset;
            }

            @media screen and (max-width: 600px) {
                align-items: center;

                &>span {
                    text-align: center;
                }
            }
        }
    }

    &__nav {
        font-size: 21px;

        @media screen and (max-width: 600px) {
            font-size: 14px;
        }
    }

    &__link {
        // text-transform: uppercase;
        color: inherit;
        padding: 0.5rem 0px;
        font-weight: 700;
        display: block;
        text-decoration: none;

        @media screen and (max-width: 600px) {
            padding: 0px 5px;
        }

        @media (min-width: 600px) {
            padding: 0px 1.5rem;
            display: inline;
        }

        &:first-child {
            @media (min-width: 600px) {
                padding-left: 0;
            }
        }
    }

    &__link:hover {
        color: var(--b-color-text-primary-1);
    }

    &__about-link {
        display: none;

        @media (max-width: 1199px) {
            display: inline;
        }
    }

    &__mode {
        position: absolute;
        bottom: 3vh;
        right: 5vw;
        min-width: 140px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 13px;
        background: var(--b-color-bg-main-2);
        box-shadow: 4px 3px 10px 0px var(--b-shadow-light);
        color: var(--b-color-main-2);
        font-family: var(--b-font-family-primary-1);
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;

        @media screen and (max-width: 900px) {
            position: relative;
            left: unset;
            right: unset;
            bottom: unset;
            margin: 10px;
            font-size: 16px;
        }
    }
}