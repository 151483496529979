:root {
  --primary-font: var(--b-font-family-primary-1);
  --primary-color: var(--b-color-main-2);
  --secondary-color: var(--b-color-bg-main-2);
  --background: var(--b-color-text-primary-2);
  --background_2: #036;
  --text-primary: #036;
  --text-secondary: #f4f4f4;
  --font-primary: "Montserrat";
  --font-secondary: "Roboto";
  --background-image: "";
  --donut-1: rgba(0, 51, 102, 0.79);
  --donut-2: rgba(0, 51, 102, 0.415);
  --donut-3: #f4f4f4;
}

[data-theme="panda"] {
  --background: var(--b-color-text-primary-2);
  --background_2: var(--b-color-main-2);
  --text-primary: var(--b-color-main-2);
  --text-secondary: #fff3e1;
  --font-primary: var(--b-font-family-primary-1);
  --font-secondary: var(--b-font-family-primary-1);
  --background-image: var(--bgi-separator);
  --donut-1: #fe4b4e;
  --donut-2: #952325;
  --donut-3: #fff3e1;
}

[data-theme="generali"] {
  --background: var(--b-color-text-primary-2);
  --background_2: #c12017;
  --text-primary: #c12017;
  --text-secondary: var(--b-color-text-primary-2);
  --font-primary: "Montserrat";
  --font-secondary: "Montserrat";
  --background-image: var(--bgi-separator);
  --donut-1: #c11f17b0;
  --donut-2: #c11f175f;
  --donut-3: #f4f4f4;
}


:root {
  --b-color-ui-1: #fff;
  --b-color-ui-2: #fff;
  --b-color-ui-3: #fff;
  --b-color-ui-4: #fff;
  --b-color-brand-primary: var(--primary-color);
  --b-color-brand-primary-shaded: #fff;
  --b-color-support-info: var(--primary-color);
  --b-color-text-primary: #000;
  --b-color-text-secondary: #fff;
  --b-color-text-highlight: var(--primary-color);
  --b-color-text-form-placeholder: #9e9e9e;
  --b-color-text-footer: var(--b-color-text-primary);

  --b-color-text-primary-1: #312F2F;
  --b-color-text-primary-2: #FFF;
  --b-color-main-1: #fff;
  --b-color-main-2: #fff;
  --b-color-additional-1: #fff;
  --b-color-additional-2: #fff;
  --b-color-additional-3: #fff;
  --b-color-bg-main-1: #fff;
  --b-color-bg-main-2: #fff;
  --b-color-bg-main-3: #fff;
  --b-color-bg-additional-1: #fff;
  --b-color-bg-additional-2: #fff;
  --b-color-bg-additional-3: #fff;
  --b-color-bg-additional-4: #fff;
  --b-color-bg-additional-5: #fff;
  --b-color-bg-additional-6: #fff;
  --b-shadow-main: #525252;
  --b-shadow-light: #5252528c;
  --b-shadow-lighter: #5252527a;
  --b-shadow-white: #ffffffa3;
  --b-font-family-primary-1: "RooneySans";
  --b-font-family-secondary-1: "RooneySans";
  --b-font-size-sm: 12px;
  --b-font-size-s: 14px;
  --b-font-size-m: 16px;
  --b-font-size-l: 18px;
  --b-font-size-xl: 21px;
  --b-font-size-xxl: 24px;
  --b-font-size-xxxl: 34px;

  // Add color to UI kit 
  // #FCE7D2 tables - replaced by #FFE4CA
  // #FFE4CA tables - done, now it`s "--b-color-bg-additional-4"

  // Mock colors to test
  // --b-color-text-primary-1: green;
  // --b-color-main-1: green;
  // --b-color-main-2: green;
  // --b-color-additional-1: green;
  // --b-color-additional-2: green;
  // --b-color-additional-3: green;
  // --b-color-bg-main-1: green;
  // --b-color-bg-main-2: green;
  // --b-color-bg-main-3: green;
  // --b-color-bg-additional-1: green;
  // --b-color-bg-additional-2: green;
  // --b-color-bg-additional-3: green;

  // Images
  --bgi-wave-1: "";
  --bgi-wave-1-mobile: "";
  --bgi-wave-2: "";
  --bgi-wave-2-mobile: "";
  --bgi-li-marker: "";
  --bgi-footer-wave: "";
  --bgi-footer-wave-mobile: "";
  --bgi-pet-tab-left-S-en: "";
  --bgi-pet-tab-left-M-en: "";
  --bgi-pet-tab-left-L-en: "";
  --bgi-pet-tab-left-XL-en: "";
  --bgi-pet-tab-left-XXL-en: "";
  --bgi-pet-tab-left-XXXL-en: "";
  --bgi-pet-tab-left-S-de: "";
  --bgi-pet-tab-left-M-de: "";
  --bgi-pet-tab-left-L-de: "";
  --bgi-pet-tab-left-XL-de: "";
  --bgi-pet-tab-left-XXL-de: "";
  --bgi-pet-tab-left-XXXL-de: "";
  --bgi-pet-tab-left-S-en-mobile: "";
  --bgi-pet-tab-left-M-en-mobile: "";
  --bgi-pet-tab-left-L-en-mobile: "";
  --bgi-pet-tab-left-XL-en-mobile: "";
  --bgi-pet-tab-left-XXL-en-mobile: "";
  --bgi-pet-tab-left-XXXL-en-mobile: "";
  --bgi-pet-tab-left-S-de-mobile: "";
  --bgi-pet-tab-left-M-de-mobile: "";
  --bgi-pet-tab-left-L-de-mobile: "";
  --bgi-pet-tab-left-XL-de-mobile: "";
  --bgi-pet-tab-left-XXL-de-mobile: "";
  --bgi-pet-tab-left-XXXL-de-mobile: "";
  --bgi-pet-tab-left: "";
  --bgi-pet-tab-left-mobile: "";
  --bgi-pet-tab-right: "";
  --bgi-loader-animation-main: "";
  --bgi-loader-animation: "";
  --bgi-loader-animation-mobile: "";
  --bgi-loader-wave: "";
  --bgi-loader-wave-mobile: "";
  --bgi-login: "";
  --bgi-marker: "";
  --bgi-separator: "";
  --bgi-modal-head: "";
  --bgi-modal-head-mobile: "";


  // Images - Depends on language
  --bgi-change-data-en: "";
  --bgi-change-data-ge: "";
  --bgi-change-data-en-hover: "";
  --bgi-change-data-ge-hover: "";

  --bgi-change-payment-en: "";
  --bgi-change-payment-ge: "";
  --bgi-change-payment-en-hover: "";
  --bgi-change-payment-ge-hover: "";

  --bgi-product-missing-en: "";
  --bgi-product-missing-ge: "";
  --bgi-product-missing-en-hover: "";
  --bgi-product-missing-ge-hover: "";

  //Flow
  --bgi-flow-wave: "";
  --bgi-flow-wave-mobile: "";
}

body[data-theme="panda"] {
  --b-color-ui-1: #fff;
  --b-color-ui-2: #dbdbdb;
  --b-color-ui-3: #d3d3d3;
  --b-color-ui-4: #f6f6f6;
  --b-color-brand-primary: var(--primary-color);
  --b-color-brand-primary-shaded: #fff;
  --b-color-support-info: var(--primary-color);
  --b-color-text-primary: #000;
  --b-color-text-secondary: #fff;
  --b-color-text-highlight: var(--primary-color);
  --b-color-text-form-placeholder: #9e9e9e;
  --b-color-text-footer: var(--b-color-text-primary);

  --b-color-text-primary-1: #312F2F;
  --b-color-text-primary-2: #FFF;
  --b-color-main-1: #B83739;
  --b-color-main-2: #FE4B4E;
  --b-color-additional-1: #E3767A;
  --b-color-additional-2: #952325;
  --b-color-additional-3: #802123;
  --b-color-bg-main-1: #FFF9F0;
  --b-color-bg-main-2: #FFFEFA;
  --b-color-bg-main-3: #FFF2E4;
  --b-color-bg-additional-1: #F2F2F2;
  --b-color-bg-additional-2: #A09F9D;
  --b-color-bg-additional-3: #DDC9AB;
  --b-color-bg-additional-4: #FFE4CA;
  --b-color-bg-additional-5: #E7E7E7;
  --b-color-bg-additional-6: #F8D0A9;
  --b-shadow-main: #525252;
  --b-shadow-light: #5252528c;
  --b-shadow-lighter: #5252527a;
  --b-font-family-primary-1: "RooneySans";
  --b-font-family-secondary-1: "RooneySans";
  --b-font-size-sm: 12px;
  --b-font-size-s: 14px;
  --b-font-size-m: 16px;
  --b-font-size-l: 18px;
  --b-font-size-xl: 21px;
  --b-font-size-xxl: 24px;
  --b-font-size-xxxl: 34px;

  --bgi-wave-1: url("/assets/Insurances/red_stuff.png");
  --bgi-wave-1-mobile: url("/assets/Insurances/brown_stuff_mobile.svg");
  --bgi-wave-2: url("/assets/PetPage/dark_red_back.png");
  --bgi-wave-2-mobile: url("/assets/PetPage/dark_red_back_mobile.png");
  --bgi-li-marker: url("/assets/PetPage/marker_red.svg");
  --bgi-footer-wave: url("/assets/footer/background_red.png");
  --bgi-footer-wave-mobile: url("/assets/footer/background_red_mobile.png");
  --bgi-pet-tab-left-S-en: url("/assets/PetPage/back_left_cut_op_basic_eng.png");
  --bgi-pet-tab-left-M-en: url("/assets/PetPage/back_left_cut_op_comfort_eng.png");
  --bgi-pet-tab-left-L-en: url("/assets/PetPage/back_left_cut_op_full_eng.png");
  --bgi-pet-tab-left-XL-en: url("/assets/PetPage/back_left_cut_health_basic_eng.png");
  --bgi-pet-tab-left-XXL-en: url("/assets/PetPage/back_left_cut_health_comfort_eng.png");
  --bgi-pet-tab-left-XXXL-en: url("/assets/PetPage/back_left_cut_health_full_eng.png");
  --bgi-pet-tab-left-S-de: url("/assets/PetPage/back_left_cut_op_basic_ge.png");
  --bgi-pet-tab-left-M-de: url("/assets/PetPage/back_left_cut_op_comfort_ge.png");
  --bgi-pet-tab-left-L-de: url("/assets/PetPage/back_left_cut_op_full_ge.png");
  --bgi-pet-tab-left-XL-de: url("/assets/PetPage/back_left_cut_health_basic_ge.png");
  --bgi-pet-tab-left-XXL-de: url("/assets/PetPage/back_left_cut_health_comfort_ge.png");
  --bgi-pet-tab-left-XXXL-de: url("/assets/PetPage/back_left_cut_health_full_ge.png");
  --bgi-pet-tab-left-S-en-mobile: url("/assets/PetPage/back_left_cut_op_basic_mobile_eng.png");
  --bgi-pet-tab-left-M-en-mobile: url("/assets/PetPage/back_left_cut_op_comfort_mobile_eng.png");
  --bgi-pet-tab-left-L-en-mobile: url("/assets/PetPage/back_left_cut_op_full_mobile_eng.png");
  --bgi-pet-tab-left-XL-en-mobile: url("/assets/PetPage/back_left_cut_health_basic_mobile_eng.png");
  --bgi-pet-tab-left-XXL-en-mobile: url("/assets/PetPage/back_left_cut_health_comfort_mobile_eng.png");
  --bgi-pet-tab-left-XXXL-en-mobile: url("/assets/PetPage/back_left_cut_health_full_mobile_eng.png");
  --bgi-pet-tab-left-S-de-mobile: url("/assets/PetPage/back_left_cut_op_basic_mobile_ge.png");
  --bgi-pet-tab-left-M-de-mobile: url("/assets/PetPage/back_left_cut_op_comfort_mobile_ge.png");
  --bgi-pet-tab-left-L-de-mobile: url("/assets/PetPage/back_left_cut_op_full_mobile_ge.png");
  --bgi-pet-tab-left-XL-de-mobile: url("/assets/PetPage/back_left_cut_health_basic_mobile_ge.png");
  --bgi-pet-tab-left-XXL-de-mobile: url("/assets/PetPage/back_left_cut_health_comfort_mobile_ge.png");
  --bgi-pet-tab-left-XXXL-de-mobile: url("/assets/PetPage/back_left_cut_health_full_mobile_ge.png");
  --bgi-pet-tab-left: url("/assets/PetPage/pet_white_back_left_cut.png");
  --bgi-pet-tab-left-mobile: url("/assets/PetPage/pet_white_back_left_cut_mobile.png");
  --bgi-pet-tab-right: url("/assets/PetPage/pet_white_back_right.png");
  --bgi-loader-animation-main: url("/assets/loader/panda_head_animation_biege.gif");
  --bgi-loader-animation: url("/assets/loader/confetti_desktop_red.gif");
  --bgi-loader-animation-mobile: url("/assets/loader/confetti_mobile_red_background.gif");
  --bgi-loader-wave: url("/assets/loader/wave_desktop_2880x760.png");
  --bgi-loader-wave-mobile: url("/assets/loader/wave_mobile_750x720.png");
  --bgi-login: url("/assets/login/main_back.png");
  --bgi-marker: url("/assets/UserInfo/marker.svg");
  --bgi-separator: url("/assets/images/separator_curly.png");
  --bgi-modal-head: url("/assets/modal/head-back-red.svg");
  --bgi-modal-head-mobile: url("/assets/modal/head-red-mobile.svg");

  // Depends on language
  --bgi-change-data-en: url("/assets/bubbles/changeDataEN.svg");
  --bgi-change-data-ge: url("/assets/bubbles/changeDataDE.svg");
  --bgi-change-data-en-hover: url("/assets/bubbles/changeDataEN_hovered.svg");
  --bgi-change-data-ge-hover: url("/assets/bubbles/changeDataDE_hovered.svg");

  --bgi-change-payment-en: url("/assets/bubbles/changePaymentEN.svg");
  --bgi-change-payment-ge: url("/assets/bubbles/changePaymentDE.svg");
  --bgi-change-payment-en-hover: url("/assets/bubbles/changePaymentEN_hovered.svg");
  --bgi-change-payment-ge-hover: url("/assets/bubbles/changePaymentDE_hovered.svg");

  --bgi-product-missing-en: url("/assets/bubbles/productMissingEN.svg");
  --bgi-product-missing-ge: url("/assets/bubbles/productMissingDE.svg");
  --bgi-product-missing-en-hover: url("/assets/bubbles/productMissingEN_hovered.svg");
  --bgi-product-missing-ge-hover: url("/assets/bubbles/productMissingDE_hovered.svg");

  //Flow
  --bgi-flow-wave: url('/assets/background/long-wave-bg.png');
  --bgi-flow-wave-mobile: url('/assets/background/long-wave-bg.png');
}


@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(/assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(/assets/fonts/Montserrat/static/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Verveine";
  src: local("Verveine"),
    url(/assets/fonts/Verveine/VerveineRegular.TTF) format("truetype");
}

@font-face {
  font-family: "RooneySans";
  src: local("RooneySans"),
    url(/assets/fonts/RooneySans/RooneySans-Regular.ttf) format("truetype");
}