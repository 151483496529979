// LOADER

.loader-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: var(--bgi-loader-animation-mobile);
    background-size: 100% 100%;
    background-repeat: no-repeat;

    @media screen and (min-width: 600px) {
        background-image: var(--bgi-loader-animation);
    }
}

.loader-back {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3vh;
    background-image: var(--bgi-loader-wave-mobile);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 340px;
    position: relative;

    @media screen and (min-width: 600px) {
        background-image: var(--bgi-loader-wave);
        height: 240px;
    }

    @media screen and (min-width: 900px) {
        height: 300px;
        gap: 5vh;
    }

    @media screen and (min-width: 1100px) {
        height: 360px;
    }

    &::before {
        content: "";
        background-image: var(--bgi-loader-animation-main);
        position: absolute;
        bottom: 12px;
        left: 50%;
        transform: translateX(-50%);
        width: 145px;
        height: 78px;
        background-size: 100% 100%;

        @media screen and (min-width: 600px) {
            bottom: 12px;
        }

        @media screen and (min-width: 900px) {
            bottom: 18px;
        }

        @media screen and (min-width: 1100px) {
            bottom: 21px;
            width: 185px;
            height: 100px;
        }
    }
}

.loader-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: var(--b-color-bg-main-2);
    font-family: var(--b-font-family-primary-1);
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    font-variant-numeric: lining-nums proportional-nums;
    word-break: break-word;
    width: 90%;

    &>img {
        height: 6px;
    }

    @media screen and (min-width: 600px) {
        flex-direction: row;
        align-items: end;
        font-size: 34px;
        line-height: 28px;

        &>img {
            height: 8px;
        }
    }

    @media screen and (min-width: 900px) {
        font-size: 50px;
        line-height: 40px;

        &>img {
            height: 11px;
        }
    }
}

.loader-subtext {
    color: var(--b-color-bg-main-2);
    font-family: var(--b-font-family-primary-1);
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    font-variant-numeric: lining-nums proportional-nums;
    word-break: break-word;
    width: 80%;
    text-align: center;

    @media screen and (min-width: 900px) {
        font-size: 24px;
    }
}

.fullscreen-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000 !important;
}