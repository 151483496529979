// Global custom styles

.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  background-color: var(--b-color-bg-main-2);
}

.container-user-info {
  background-image: url('../assets/UserInfo/main_back.png');
  background-repeat: no-repeat;
  background-size: 100% 95%;
  // until no logic for btns
  // background-size: cover;
  background-color: transparent !important;
  padding: 0 0 100px 0;
  z-index: 6;
  width: 100%;

  @media screen and (max-width: 1200px) {
    background-image: url('../assets/UserInfo/main_back_mobile.png');
    background-size: 100% 95%;
  }
}

.panda-foot {
  width: 210px;
  height: 210px;
  position: absolute;
  left: 12%;
  top: 190px;
}

.container-form {
  position: relative;
  width: 1100px;
  margin: 0 auto;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-content {
  display: flex;
  align-items: center;
  flex-direction: column;

  &>* {
    // padding-top: 4.5em;
    // padding-bottom: 4.5em;
  }
}

.gap_centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 40px 10px;

  @media screen and (max-width: 600px) {
    margin: 20px 10px;
  }

  &__item {
    width: 100%;
    min-height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    align-items: flex-end;
    justify-content: center;

    @media screen and (max-width: 600px) {
      min-height: 20px;
    }

    &__left {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
      row-gap: 6px;
      color: var(--b-color-text-primary-1);
      text-align: right;
      font-family: var(--b-font-family-primary-1);
      font-size: 24px;
      font-style: normal;
      font-weight: bold;
      line-height: normal;

      @media screen and (max-width: 900px) {
        font-size: 18px;
      }

      @media screen and (max-width: 600px) {
        font-size: 14px;
        row-gap: 4px;
      }
    }

    &__right {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      row-gap: 6px;
      color: var(--b-color-text-primary-1);
      font-variant-numeric: lining-nums proportional-nums;
      font-family: var(--b-font-family-primary-1);
      font-size: 24px;
      font-style: normal;
      font-weight: bold;
      line-height: normal;

      @media screen and (max-width: 900px) {
        font-size: 18px;
      }

      @media screen and (max-width: 600px) {
        font-size: 14px;
        row-gap: 4px;
      }
    }
  }
}

.modal-wtd {
  color: var(--b-color-text-primary-1);
  font-family: var(--b-font-family-primary-1);
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1300;

  & img {
    width: 200px;
  }

  &__background {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.92);
    z-index: -1;

    &__loader {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__back_bar {
    position: absolute;
    top: 48vh;
    left: 14vw;
    cursor: pointer;
    background-color: var(--b-color-main-2);
    height: 40px;
    padding: 13px 15px;
    border-radius: 13px;
    box-shadow: 4px 3px 10px 0px var(--b-shadow-main);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    &>span {
      color: var(--b-color-text-primary-2);
      font-size: 21px;
      font-family: var(--b-font-family-primary-1);
      line-height: normal;
    }

    &>img {
      height: 30px;
      width: unset;
    }

    &.mobile {
      top: 5vh;
      left: 5vw;

      @media screen and (orientation: landscape) {
        top: 45vh;
      }

      &>span {
        width: auto;
        font-size: 16px;
        z-index: 2;
        width: 70%;
      }

      &>img {
        position: static;
        width: 16px;
      }
    }

    &.tablet {
      left: 5vw;
      top: 44vh;

      @media screen and (orientation: landscape) {
        top: 48vh;
      }
    }
  }

  &__back_bar:hover {
    background-color: var(--b-color-main-1);
  }

  &__button {
    position: absolute;
    top: 35vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;

    &>img {
      position: relative;
      width: 170px;
      height: 96px;
      margin: 0 0 -20px 0;
    }

    &.mobile {
      top: 39vh;

      @media screen and (orientation: landscape) {
        top: 26vh;
      }

      @media screen and (max-width: 900px) {
        top: 30vh;
      }

      @media screen and (max-width: 600px) {
        top: 35vh;
      }

      @media screen and (max-width: 400px) {
        top: 40vh;

      }

      &>img {
        width: 125px;
      }
    }

    &.tablet {
      top: 36vh;
    }
  }

  &__type,
  &__type-disabled {
    &.send_bill {
      position: absolute;
      top: 24vh;
      left: 27vw;
      cursor: pointer;

      @media screen and (max-width: 1200px) and (orientation: landscape) {
        top: 27vh;
        left: 22vw;
      }

      @media screen and (orientation: portrait) {
        top: 27vh;
        left: 10vw;
      }

      &>img {
        width: 100%;
      }

      &.mobile {
        top: 27vh;
        left: 5vw;

        @media screen and (orientation: landscape) {
          top: 14vh;
          left: 17vw;
          width: 160px;
        }

        @media screen and (max-width: 900px) and (orientation: portrait) {
          width: 170px;
        }

        @media screen and (max-width: 400px) and (orientation: portrait) {
          width: 140px;
        }
      }

      &.tablet {
        left: 15vw;
      }
    }

    &.report_liab {
      position: absolute;
      top: 7vh;
      left: 45vw;
      cursor: pointer;

      @media screen and (orientation: portrait) {
        top: 13vh;
        left: 38vw;
      }

      &>img {
        width: 100%;
      }

      &.mobile {
        top: 10vh;
        left: 33vw;

        @media screen and (orientation: landscape) {
          top: 0vh;
          left: 44vw;
          width: 180px;
        }

        @media screen and (max-width: 900px) and (orientation: portrait) {
          top: 13vh;
          left: 30vw;
          width: 195px;
        }

        @media screen and (max-width: 600px) and (orientation: portrait) {
          top: 10vh;
          left: 30vw;
        }

        @media screen and (max-width: 400px) and (orientation: portrait) {
          top: 8vh;
          left: 30vw;
          width: 160px;
        }
      }

      &.tablet {
        top: 10vh;
        left: 41vw;
      }
    }

    &.resend_docs {
      position: absolute;
      top: 36vh;
      left: 67vw;
      cursor: pointer;

      @media screen and (orientation: portrait) {
        top: 30vh;
        left: 71vw;
      }

      &>img {
        width: 100%;
      }

      &.mobile {
        top: 29vh;
        left: 62vw;

        @media screen and (orientation: landscape) {
          top: 21vh;
          left: 68vw;
          width: 140px;
        }

        @media screen and (max-width: 900px) and (orientation: portrait) {
          top: 24vh;
          width: 150px;
        }

        @media screen and (max-width: 400px) and (orientation: portrait) {
          top: 24vh;
          width: 120px;
        }
      }

      &.tablet {
        top: 26vh;
        left: 71vw;
      }
    }

    &.cost_calc {
      position: absolute;
      top: 60vh;
      left: 58vw;
      cursor: pointer;

      @media screen and (orientation: portrait) {
        top: 56vh;
        left: 60vw;
      }

      &>img {
        width: 100%;
      }

      &.mobile {
        top: 54vh;
        left: 56vw;

        @media screen and (orientation: landscape) {
          top: 60vh;
          left: 57vw;
          width: 180px;
        }

        @media screen and (max-width: 900px) and (orientation: portrait) {
          top: 54vh;
          left: 56vw;
          width: 180px;
        }

        @media screen and (max-width: 600px) and (orientation: portrait) {
          top: 54vh;
          left: 52vw;
          width: 195px;
        }

        @media screen and (max-width: 400px) and (orientation: portrait) {
          top: 64vh;
          left: 56vw;
          width: 150px;
        }
      }

      &.tablet {
        top: 55vh;
        left: 58vw;
      }
    }

    &.bill_dir {
      position: absolute;
      top: 59vh;
      left: 29vw;
      cursor: pointer;

      @media screen and (orientation: portrait) {
        top: 59vh;
        left: 17vw;
      }

      &>img {
        width: 100%;
      }

      &.mobile {
        top: 55vh;
        left: 6vw;

        @media screen and (orientation: landscape) {
          top: 61vh;
          left: 23vw;
          width: 180px;
        }

        @media screen and (max-width: 900px) and (orientation: portrait) {
          top: 55%;
          left: 2vw;
          width: 200px;
        }

        @media screen and (max-width: 600px) and (orientation: portrait) {
          top: 55%;
          left: 5vw;
          width: 200px;
        }

        @media screen and (max-width: 400px) and (orientation: portrait) {
          top: 65%;
          left: 6vw;
          width: 150px;
        }
      }

      &.tablet {
        top: 56vh;
        left: 20vw;
      }
    }
  }

  &__type:hover {
    scale: 1.23;
    transition: 0.3s;
  }

  &__type-disabled {
    cursor: unset !important;

    &>* {
      opacity: 0.4;
    }
  }
}

.docs-container {
  width: 620px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

.welcome_text {
  position: relative;
  width: 1100px;
  z-index: 7;
  margin: 10vh 0vw 0;
  color: var(--b-color-text-primary-1);
  font-family: var(--b-font-family-primary-1);
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media screen and (max-width: 1200px) {
    width: 100%;
    text-align: start;
    padding: 5vh;
    margin: 3.5vh 0 0 0;
    font-size: 18px;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    text-align: center;
    margin: 3.5vh 0 0 0;
    padding: 0;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    margin: 3.5vh 0 0 0;
    text-align: center;
    font-size: 16px;
    font-weight: 800;
    padding: 0;
  }

  @media screen and (orientation: landscape) and (max-width: 1200px) {
    padding: 2vh 5vw;
  }

  &.margin-0 {
    margin: 0;
  }
}

.upload-color {
  display: block;
  width: 25px;
  height: 25px;
  background-color: var(--b-color-main-2);
  border-radius: 50%;
}

.errorMessage {
  text-align: center;
  padding: 0 0 10px 0;
}

.stripe-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stripe-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.StripeElement {
  width: 100%;
}

.pdf-viewer {
  &.pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0 10px 0;
  }
}