.reset-table-spacing {
    table {
        border-collapse: separate;
        border-spacing: 6px 4px;
        padding: 0 0 0 10px;
    }
}

.policy-table {

    td:first-child,
    th:first-child {
        width: 20%;
    }

    td:nth-child(2),
    th:nth-child(2) {
        width: 17%;
    }

    td:nth-child(3),
    th:nth-child(3) {
        width: 18%;
    }

    td:nth-child(4),
    th:nth-child(4) {
        width: 20%;
    }

    td:nth-child(5),
    th:nth-child(5) {
        width: 15%;
    }

    td:nth-child(6),
    th:nth-child(6) {
        width: 10%;
    }

}

.policy-table-admin {

    td:first-child,
    th:first-child {
        width: 18%;
    }

    td:nth-child(2),
    th:nth-child(2) {
        width: 12%;
    }

    td:nth-child(3),
    th:nth-child(3) {
        width: 15%;
    }

    td:nth-child(4),
    th:nth-child(4) {
        width: 15%;
    }

    td:nth-child(5),
    th:nth-child(5) {
        width: 15%;
    }

    td:nth-child(6),
    th:nth-child(6) {
        width: 10%;
    }

    td:nth-child(7),
    th:nth-child(7) {
        width: 15%;
    }
}

.modal-restyle {

    td:first-child,
    th:first-child {
        width: 20%;
    }

    td:nth-child(2),
    th:nth-child(2) {
        width: 30%;
    }

    td:nth-child(3),
    th:nth-child(3) {
        width: 12%;
    }

    td:nth-child(4),
    th:nth-child(4) {
        width: 13%;
    }

    td:nth-child(5),
    th:nth-child(5) {
        width: 10%;
    }

    td:nth-child(6),
    th:nth-child(6) {
        width: 15%;
    }
}

.modal-restyle-edit-rows {

    td:first-child,
    th:first-child {
        width: 5%;
    }

    td:nth-child(2),
    th:nth-child(2) {
        width: 20%;
    }

    td:nth-child(3),
    th:nth-child(3) {
        width: 35%;
    }

    td:nth-child(4),
    th:nth-child(4) {
        width: 10%;
    }

    td:nth-child(5),
    th:nth-child(5) {
        width: 10%;
    }

    td:nth-child(6),
    th:nth-child(6) {
        width: 10%;
    }

    td:nth-child(7),
    th:nth-child(7) {
        width: 10%;
    }
}

.modal-restyle-edit-values {

    td:first-child,
    th:first-child {
        width: 3%;
    }

    td:nth-child(2),
    th:nth-child(2) {
        width: 20%;
    }

    td:nth-child(3),
    th:nth-child(3) {
        width: 37%;
    }

    td:nth-child(4),
    th:nth-child(4) {
        width: 10%;
    }

    td:nth-child(5),
    th:nth-child(5) {
        width: 10%;
    }

    td:nth-child(6),
    th:nth-child(6) {
        width: 10%;
    }

    td:nth-child(7),
    th:nth-child(7) {
        width: 10%;
    }
}

.modal-add-line-btn {

    td:first-child,
    th:first-child {
        width: 3%;
    }

    td:nth-child(2),
    th:nth-child(2) {
        width: 97%;
    }
}

.modal-info {

    td:first-child,
    th:first-child {
        width: 65%;
    }

    td:nth-child(2),
    th:nth-child(2) {
        width: 15%;
    }

    td:nth-child(3),
    th:nth-child(3) {
        width: 20%;
    }
}

.modal-info-admin {

    td:first-child,
    th:first-child {
        width: 60%;
    }

    td:nth-child(2),
    th:nth-child(2) {
        width: 10%;
    }

    td:nth-child(3),
    th:nth-child(3) {
        width: 15%;
    }

    td:nth-child(4),
    th:nth-child(4) {
        width: 15%;
    }
}

.table-flows {

    td:first-child,
    th:first-child {
        width: 30%;
    }

    td:nth-child(3),
    th:nth-child(3) {
        width: 32%;
    }
}

.table-dropdowns {

    td:first-child,
    th:first-child {
        width: 20%;
    }

    td:nth-child(3),
    th:nth-child(3) {
        width: 25%;
    }
}

.table-payment {

    td:first-child,
    th:first-child {
        width: 4%;
    }

    td:nth-child(2),
    th:nth-child(2) {
        width: 14%; /* Adjusted */
    }

    td:nth-child(3),
    th:nth-child(3) {
        width: 14%; /* Adjusted */
    }

    td:nth-child(4),
    th:nth-child(4) {
        width: 14%; /* Adjusted */
    }

    td:nth-child(5),
    th:nth-child(5) {
        width: 14%; /* Adjusted */
    }

    td:nth-child(6),
    th:nth-child(6) {
        width: 14%; /* Adjusted */
    }

    td:nth-child(7),
    th:nth-child(7) {
        width: 14%; /* Adjusted */
    }

    td:nth-child(8),
    th:nth-child(8) {
        width: 10%; /* New date column */
    }
}

.table-refund {

    td:first-child,
    th:first-child {
        width: 4%;
    }

    td:nth-child(2),
    th:nth-child(2) {
        width: 16%;
    }

    td:nth-child(3),
    th:nth-child(3) {
        width: 30%;
    }

    td:nth-child(4),
    th:nth-child(4) {
        width: 15%;
    }

    td:nth-child(5),
    th:nth-child(5) {
        width: 20%;
    }

    td:nth-child(6),
    th:nth-child(6) {
        width: 15%;
    }
}

.existing {
    table {

        td:first-child,
        th:first-child {
            width: 5%;
        }

        td:nth-child(2),
        th:nth-child(2) {
            width: 15%;
        }

        td:nth-child(3),
        th:nth-child(3) {
            width: 20%;
        }

        td:nth-child(4),
        th:nth-child(4) {
            width: 25%;
        }

        td:nth-child(5),
        th:nth-child(5) {
            width: 15%;
        }

        td:nth-child(6),
        th:nth-child(6) {
            width: 10%;
        }

        td:nth-child(7),
        th:nth-child(7) {
            width: 10%;
        }
    }
}

div.table-reset-header {
    overflow: hidden;
}

.reset-table-new {
    min-width: 1100px;
    // min-width: 100%;

    & * {
        word-wrap: break-word;
        word-break: break-word;
    }

    th.cell-transparent,
    td.cell-transparent {
        background-color: transparent !important;
        position: relative;

        &>img.reset-delete-line {
            position: absolute;
            z-index: 10;
            cursor: pointer !important;
            box-shadow: 2px 2px 6px 0px var(--b-shadow-main);
            border-radius: 50%;
            transform: translateY(-50%);
            top: 50%;
            right: -15px;
        }
    }

    &__header {
        height: 68px;

        th.MuiTableCell-head {
            height: auto;
            text-align: center;
            color: var(--b-color-text-primary-2);
            font-family: var(--b-font-family-primary-1);
            font-size: 21px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding: 0;
            border: unset;

            @media screen and (max-width:1100px) {
                font-size: 18px;
            }

            background-color: var(--b-color-main-1);

            &>div {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 5px;
            }

            &>div>div {
                display: flex;
                flex-direction: row;
                gap: 10px;

                width: 100%;
                justify-content: center;
            }

            & img {
                flex: 0 0 20px;
            }
        }

        th.MuiTableCell-head:first-child {
            border-radius: 13px 0px 0px 13px;
        }

        &.edit-rows,
        &.updating-row {
            th.MuiTableCell-root:nth-child(2) {
                border-bottom-left-radius: 13px;
                border-top-left-radius: 13px;
            }
        }

        th.MuiTableCell-head:last-child {
            border-radius: 0px 13px 13px 0px;
        }

    }

    &__body {
        cursor: pointer;
        height: 48px;

        td.MuiTableCell-root {
            height: auto;
            text-align: center;
            color: var(--b-color-text-primary-1);
            font-family: var(--b-font-family-primary-1);
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            font-variant-numeric: lining-nums proportional-nums;
            padding: 0;
            border: unset;

            @media screen and (max-width:1100px) {
                font-size: 16px;
            }

            background-color: var(--b-color-bg-main-3);

            &>div {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 4px 10px;

                &>span {
                    word-wrap: break-word;
                    word-break: break-all;
                }

                &>div.initial-value {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    border-bottom: 2px dashed;
                    height: 48px;
                }
            }
        }

        &.color-modal {
            td.MuiTableCell-root {
                background-color: var(--b-color-bg-additional-4);
            }
        }

        &.edit-selected {
            td.MuiTableCell-root {
                opacity: 0.5 !important;
            }
        }

        &.edit-unselected {
            td.MuiTableCell-root {
                background-color: var(--b-color-additional-1);
                color: var(--b-color-bg-main-2) !important;
            }
        }

        &.cell-add-line {
            td.MuiTableCell-root {
                background-color: var(--b-color-additional-1);
                color: var(--b-color-bg-main-2) !important;
                border-radius: 13px;
            }
        }

        &.updating-row,
        &.info {
            td.MuiTableCell-root {
                background-color: var(--b-color-additional-1);
                color: var(--b-color-bg-main-2) !important;
            }
        }

        &.total {
            td.MuiTableCell-root {
                background-color: var(--b-color-bg-additional-6);
                color: var(--b-color-text-primary-1);
            }
        }

        td.MuiTableCell-root.LessPadding {
            padding: 5px 10px;
        }

        td.MuiTableCell-root:first-child {
            border-bottom-left-radius: 13px;
            border-top-left-radius: 13px;
        }

        &.edit-rows,
        &.updating-row,
        &.updating-mode {
            td.MuiTableCell-root:nth-child(2) {
                border-bottom-left-radius: 13px;
                border-top-left-radius: 13px;
            }
        }

        td.MuiTableCell-root:last-child {
            border-bottom-right-radius: 13px;
            border-top-right-radius: 13px;
        }

    }

    &__info {
        td {
            background-color: var(--b-color-bg-main-1);
            color: var(--b-color-text-primary-1);
            border-radius: 13px;
            text-align: start !important;
            padding: 40px !important;
            font-weight: 400 !important;
            font-family: var(--b-font-family-primary-1);
            font-variant-numeric: lining-nums proportional-nums;
        }

        ul {
            list-style-type: none;
            padding-left: 10px;
        }

        li {
            padding-left: 12px;
            background-image: var(--bgi-marker);
            background-position: 2px 7px;
            background-size: 6px;
            background-repeat: no-repeat;

            span {
                color: #52BB37;
            }
        }

        &__container {
            width: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 10px;

            @media screen and (min-width: 600px) {
                flex-direction: row;
                row-gap: 15px;
                justify-content: space-between;
            }
        }

        &__block {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            background-color: var(--b-color-bg-main-3);
            padding: 26px 10px;
            border-radius: 13px;
            font-size: var(--b-font-size-m);
            font-weight: 500;

            @media screen and (min-width: 600px) {
                width: 48%;
            }

            &>div {
                font-size: var(--b-font-size-l);
                font-family: var(--b-font-family-primary-1);
                font-weight: 600;
            }
        }
    }
}

.reset-table {
    th.MuiTableCell-head {
        height: 68px !important;
        text-align: center;
        color: var(--b-color-text-primary-2);
        font-family: var(--b-font-family-primary-1);
        font-size: 21px !important;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 0;
        background-color: var(--b-color-bg-main-2);
        border: unset;

        @media screen and (max-width:1100px) {
            font-size: 18px !important;
        }

        &>div {
            background-color: var(--b-color-main-1);
            width: 100%;
            height: 100%;
        }

        &>div>div {
            padding: 10px 10px;
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
    }

    th.MuiTableCell-head:first-child {
        &>div {
            border-radius: 13px 0px 0px 13px;
        }
    }

    th.MuiTableCell-head:last-child {
        &>div {
            border-radius: 0px 13px 13px 0px;
        }
    }

    th.MuiTableCell-head:not(:last-child) {
        border-right: 6px solid var(--b-color-bg-main-2);
    }

    td.MuiTableCell-root {
        padding: 10px;
        height: 28px;
        text-align: center;
        color: var(--b-color-text-primary-1);
        font-family: var(--b-font-family-primary-1);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        font-variant-numeric: lining-nums proportional-nums;

        @media screen and (max-width:1100px) {
            font-size: 16px !important;
        }
    }

    td.MuiTableCell-root.LessPadding {
        padding: 5px 10px;
    }

    div.MuiModal-root {
        margin: 0;
    }
}

div.input-editable-invoice {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    font-family: var(--b-font-family-primary-1);
    font-weight: 600;
    font-variant-numeric: lining-nums proportional-nums;
    padding: 0 5px;
}

div.input-edit-tr {
    width: 100%;
    height: 38px;
    margin: 5px;
    padding: 0 5px;
    background-color: var(--b-color-bg-additional-4);
    font-family: var(--b-font-family-primary-1);
    font-size: var(--b-font-size-l);
    font-weight: 600;
    font-variant-numeric: lining-nums proportional-nums;

    &>input {
        text-align: center;
    }
}

.chat-content div.MuiFormControl-root>div.MuiInputBase-root {
    border-radius: 14px;
}

.chat-content div.MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 14px;

}

//==================================================================

// .reset-input .MuiInputBase-root.MuiInput-root {
//     border-radius: 14px;
// }

//==================================================================

// .reset-dp .MuiOutlinedInput-notchedOutline {
//     border: none;
// }

// .reset-dp div.MuiInputBase-root.MuiOutlinedInput-root {
//     height: 47px;
//     width: 175px;
//     border: 2px solid var(--b-color-main-1);
//     border-radius: 14px;
//     > input {
//         color: var(--b-color-main-1);
//         font-variant-numeric: lining-nums proportional-nums;
//         font-family: var(--b-font-family-primary-1);
//         font-size: 18px;
//         font-style: normal;
//         font-weight: 420;
//         line-height: normal;
//     }
// }

// .reset-dp .MuiSvgIcon-root {
//     color: var(--b-color-main-1) !important;
// }

.reset-dp {
    label.MuiFormLabel-root {
        position: absolute;
        top: -7px;
    }

    div.MuiInputBase-root.MuiOutlinedInput-root {
        height: 47px;
        width: 200px;
        border: 2px solid var(--b-color-main-1);
        border-radius: 12px;

        >input {
            color: var(--b-color-main-1);
            font-variant-numeric: lining-nums proportional-nums;
            font-family: var(--b-font-family-primary-1);
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .MuiSvgIcon-root {
        color: var(--b-color-main-1);
    }

    .MuiOutlinedInput-notchedOutline {
        border: none;
    }
}

.reset-dp-modal {
    label.MuiFormLabel-root {
        position: absolute;
        top: -7px;
    }

    div.MuiFormControl-root.MuiTextField-root.MuiFormControl-root-MuiTextField-root {
        width: 100% !important;
    }

    div.MuiInputBase-root.MuiOutlinedInput-root {
        height: 40px;
        width: 100%;
        // width: 260px;
        border-radius: 13px;
        border: 1px solid var(--b-color-bg-additional-5);
        background: var(--b-color-bg-main-2);
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.65);

        >input {
            color: var(--b-color-text-primary-1);
            font-variant-numeric: lining-nums proportional-nums;
            font-family: var(--b-font-family-primary-1);
            font-size: 21px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: start;
            padding-left: 30px;

            @media screen and (max-width: 760px) {
                padding-left: 15px;
            }
        }

        @media screen and (max-width: 760px) {
            height: 30px;
            width: 100%;

            >input {
                font-size: 16px;
            }
        }
    }

    .MuiSvgIcon-root {
        color: var(--b-color-main-2);
    }

    .MuiOutlinedInput-notchedOutline {
        border: none;
    }
}

.reset-dp-modal-opacity {
    @extend .reset-dp-modal;

    div.MuiInputBase-root.MuiOutlinedInput-root {
        >input {
            color: var(--b-color-bg-additional-2);
        }
    }
}

.custom_blured_background {
    overflow-y: scroll;
    outline: none;
    scrollbar-width: none;
    /* Hide scrollbar for Firefox */
    -ms-overflow-style: none;
    /* Hide scrollbar for IE and Edge */

    div.MuiBackdrop-root.MuiModal-backdrop {
        background-color: rgba(255, 255, 255, 0.8);
    }
}

.custom_blured_background::-webkit-scrollbar {
    width: 0px;
    /* Hide scrollbar for Chrome, Safari, and Opera */
}

.reset-pagination {
    ul.MuiPagination-ul {
        flex-wrap: nowrap;

        li:first-child,
        li:last-child {
            button {
                background-color: transparent;
            }
        }
    }

    .MuiPaginationItem-root {
        color: var(--b-color-text-primary-2);
        margin: 0 2px;
        background-color: var(--b-color-additional-1);
        leading-trim: both;
        text-edge: cap;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: var(--b-font-family-primary-1);
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 32px;
    }

    .Mui-selected {
        background-color: var(--b-color-main-2) !important;
    }

    .MuiPaginationItem-root:hover {
        background-color: var(--b-color-additional-1);
    }
}

.inverted-colors {
    &.edit-selected {
        td.MuiTableCell-root {
            background-color: var(--b-color-additional-1);
            color: var(--b-color-bg-main-2);
            opacity: 1 !important;
        }
    }

    &.edit-unselected {
        td.MuiTableCell-root {
            background-color: var(--b-color-bg-additional-4);
            color: var(--b-color-text-primary-1) !important;
        }
    }
}

.inverted-mobile-colors {
    & {
        td:not(:first-child) {
            background-color: var(--b-color-bg-additional-4) !important;
            color: var(--b-color-text-primary-1) !important;
        }
    }

    &.selected {
        td:not(:first-child) {
            background-color: var(--b-color-additional-1) !important;
            color: var(--b-color-bg-main-2) !important;
            opacity: 1 !important;
        }
    }
}

#treatment-label-id.Mui-focused,
#treatment-label-id.MuiFormLabel-filled {
    top: -10px;
}

// CustomTooltip

.MuiTooltip-popper .MuiTooltip-tooltip {
    // background-color: var(--b-color-main-1);
    color: var(--b-color-bg-main-1);
    font-family: var(--b-font-family-primary-1);
}

// .MuiTooltip-popper span.MuiTooltip-arrow::before {
// border-color: transparent var(--b-color-main-1) transparent transparent;
// }

// Login

div.login-otp {
    gap: 5px;

    input {
        height: 40px;
        width: 30px;
        border-radius: 13px;
        box-shadow: 0px 0px 8px 0px var(--b-shadow-main);
        background: var(--b-color-bg-main-2);
        color: var(--b-color-text-primary-1);
        font-family: var(--b-font-family-primary-1);
        font-size: 20px;
        font-weight: 600;
        font-style: normal;
        line-height: 24px;
        leading-trim: both;
        text-edge: cap;
        font-variant-numeric: lining-nums proportional-nums;
        border: 1px solid var(--b-color-bg-additional-5);
        padding: 0px;
    }
}

.login-otp fieldset {
    border: none !important;
}

// 

.arrow-expand-item {
    position: absolute;
    left: -16px;
    box-shadow: -3px -3px 5px 0px var(--b-shadow-light);
    border-radius: 50%;
    transform: rotate(180deg);
}

.arrow-collapse-item {
    position: absolute;
    left: 30px;
    top: -10px;
    box-shadow: 3px 3px 5px 0px var(--b-shadow-light);
    border-radius: 50%;
    cursor: pointer;
}